/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { type ServerError } from '@apollo/client/link/utils'
import fetch from 'cross-fetch'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useContext, useMemo, type ReactElement, type ReactNode } from 'react'
import { AuthContext } from '../auth/AuthContext'

/**
 * ApolloProvider with the client
 */
export const ClientProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const { isAuthenticated, loginWithRedirect, getTokenSilently } = useContext(AuthContext)

  const { site } = useStaticQuery(
    graphql`
      query GetPathPrefix {
        site {
          pathPrefix
        }
      }
    `
  )
  const pathPrefix = process.env.NODE_ENV === 'development' ? '' : site?.pathPrefix

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: `${process.env.GATSBY_API}`,
      credentials: 'include',
      fetch
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      console.group('[errorLink]')

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        })
      }

      if (networkError && (networkError as ServerError).statusCode === 401) {
        if (!isAuthenticated) {
          console.log('[NetworkError] Unauthorized request')
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          ;(async (): Promise<void> => {
            await loginWithRedirect({
              returnTo: window.location.pathname.replace(pathPrefix, '')
            })
          })()
        }
      } else {
        console.log(`[NetworkError] ${networkError}`)
      }

      console.groupEnd()
    })

    const retryLink = new RetryLink({
      attempts: async () => {
        if (isAuthenticated) {
          const tokenRequest = await getTokenSilently()
          return !!tokenRequest
        }

        return false
      }
    })

    return new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              orderHistory: {
                keyArgs: false,
                merge(existing, incoming, { readField }) {
                  const mergedOrders = existing?.orders ? [...existing.orders] : []
                  const incomingOrders = incoming?.orders ? [...incoming.orders] : []
                  const filteredIncomingOrders = incomingOrders.filter(
                    order =>
                      order !== null && order !== undefined && Object.keys(order).length !== 0
                  )
                  filteredIncomingOrders.forEach((order: any) => {
                    if (
                      !mergedOrders.some(
                        existingOrder =>
                          readField('orderNumber', existingOrder) === order?.orderNumber
                      )
                    ) {
                      mergedOrders.push(order)
                    }
                  })
                  return {
                    orders: mergedOrders,
                    totalPages: incoming.totalPages,
                    currentPage: incoming.currentPage,
                    pageSize: incoming.pageSize,
                    firstPage: incoming.firstPage,
                    lastPage: incoming.lastPage,
                    nextPage: incoming.nextPage
                  }
                },

                read(existing) {
                  if (existing) {
                    const existingOrders = existing?.orders ? [...existing.orders] : []
                    const filteredOrders = existingOrders.filter(
                      order =>
                        order !== null && order !== undefined && Object.keys(order).length !== 0
                    )
                    return {
                      orders: filteredOrders,
                      totalPages: existing.totalPages,
                      currentPage: existing.currentPage,
                      pageSize: existing.pageSize,
                      firstPage: existing.firstPage,
                      lastPage: existing.lastPage,
                      nextPage: existing.nextPage
                    }
                  }
                }
              }
            }
          }
        }
      }),
      link: from([errorLink, retryLink, httpLink])
    })
  }, [isAuthenticated, pathPrefix])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
