import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { initialAuthState, authReducer } from './authState'
import { type AppState, type AuthProviderOptions, type AuthContextInterface } from './types'
import { hasAuthParams, loginError, tokenError } from './utils'
import WebAuth from './webAuth'

/**
 * Throw error if provider never updates context
 */
const stub = (): never => {
  throw new Error('Wrap component with <AuthProvider>')
}

export const AuthContext = createContext<AuthContextInterface>({
  ...initialAuthState,
  getTokenSilently: stub,
  loginWithRedirect: stub,
  logout: stub
})

export const AuthProvider = (opts: AuthProviderOptions): JSX.Element => {
  const { children, onRedirectCallback, ...clientOpts } = opts
  const [client] = useState(() => WebAuth(clientOpts))
  const [state, dispatch] = useReducer(authReducer, initialAuthState)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async (): Promise<void> => {
      try {
        if (hasAuthParams()) {
          const { appState } = await client.handleRedirectCallback()
          onRedirectCallback(appState)
        }
      } catch (error) {
        dispatch({ type: 'ERROR', error: loginError(error) })
      }
    })()
  }, [client, onRedirectCallback])

  const loginWithRedirect = useCallback(
    async (opts?: AppState): Promise<void> => {
      await client.loginWithRedirect(opts)
    },
    [client]
  )

  const logout = useCallback(
    async (opts: AppState = {}): Promise<void> => {
      await client.logout(opts)
    },
    [client]
  )

  const getTokenSilently = useCallback(
    async (opts?: any): Promise<any> => {
      let token
      try {
        token = await client.getTokenSilently(opts)
      } catch (error) {
        throw tokenError(error)
      } finally {
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client.getUser()
        })
      }
      return token
    },
    [client]
  )

  const contextValue = useMemo(
    () => ({
      ...state,
      loginWithRedirect,
      logout,
      getTokenSilently
    }),
    [state, loginWithRedirect, logout, getTokenSilently]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
