import { v4 as uuidv4 } from 'uuid'
import { type AuthenticationResult } from './types'

export const generateCodeVerifier = (): any => uuidv4()

export const generateCodeChallenge = async (verifier: string): Promise<any> => {
  const msgUint8 = new TextEncoder().encode(verifier)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8)
  const hashHex = Array.from(new Uint8Array(hashBuffer))
    .map(function (b) {
      return b.toString(16).padStart(2, '0')
    })
    .join('')

  return window.btoa(hashHex)
}

export const parseQueryResult = (queryString: string): AuthenticationResult => {
  if (queryString.includes('#')) {
    queryString = queryString.substring(0, queryString.indexOf('#'))
  }

  const queryParams = queryString.split('&')
  const parsedQuery: Record<string, any> = {}

  queryParams.forEach(qp => {
    const [key, val] = qp.split('=')
    parsedQuery[key] = decodeURIComponent(val)
  })

  if (parsedQuery.expires_in) {
    parsedQuery.expires_in = parseInt(parsedQuery.expires_in)
  }

  return parsedQuery as AuthenticationResult
}

export const hasAuthParams = (searchParams = window.location.search): boolean =>
  /[?&](code|state|error)=[^&]+/.test(searchParams)

const normalizeError =
  (message: string) =>
  (
    error: Error | { error: string; error_description?: string } | ProgressEvent | unknown
  ): Error => {
    if (error instanceof Error) return error

    return new Error(message)
  }

export const loginError = normalizeError('Login failed')
export const tokenError = normalizeError('Get access token failed')
