export const CACHE_KEY_PREFIX = '@@myaccount@@'
export const CACHE_KEY_USER_SUFFIX = '@@customer@@'
// export const CACHE_KEY = `${CACHE_KEY_PREFIX}::finl-web::${CACHE_KEY_USER_SUFFIX}`

const cacheKey = (clientId: string, prefix = CACHE_KEY_PREFIX, suffix?: string): any =>
  [prefix, clientId, suffix].filter(Boolean).join('::')

export const cacheInMemory = ((cache: any = {}) => ({
  set: (key: string, entry: any) => {
    cache[key] = entry
  },
  get: (key: string): any => (cache[key] ? cache[key] : undefined),
  remove: (key: string) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete cache[key]
  },
  allKeys: (): string[] => Object.keys(cache)
}))()

export const cacheManager = (cache: any): any => ({
  setUser: async (clientId: string, refreshToken: string, userInfo: any) => {
    const key = cacheKey(clientId, CACHE_KEY_PREFIX, CACHE_KEY_USER_SUFFIX)
    await cache.set(key, { refreshToken, userInfo })
  },
  getUser: async (clientId: string): Promise<any | undefined> => {
    const key = cacheKey(clientId, CACHE_KEY_PREFIX, CACHE_KEY_USER_SUFFIX)
    const entry = await cache.get(key)

    if (!entry) return

    return {
      refreshToken: entry.refreshToken,
      userInfo: entry.userInfo
    }
  },
  set: async (entry: any) => {
    const key = cacheKey(entry.clientId)
    const wrappedEntry = {
      body: entry,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      expiresAt: Math.floor(Date.now() / 1000) + entry.expiresIn
    }

    await cache.set(key, wrappedEntry)
  },
  get: async (clientId: string) => {
    const key = cacheKey(clientId)
    const wrappedEntry = await cache.get(key)

    if (!wrappedEntry) return

    // Determine if the current token is expiring within 60 seconds
    if (wrappedEntry.expiresAt - 60 < Math.floor(Date.now() / 1000)) {
      if (wrappedEntry.body.refreshToken) {
        wrappedEntry.body = {
          refresh_token: wrappedEntry.body.refreshToken
        }

        await cache.set(key, wrappedEntry)
        return wrappedEntry.body
      }

      await cache.remove(key)

      return
    }

    return wrappedEntry.body
  },
  clear: async () => {
    const keysToRemove = cache.allKeys()
    await keysToRemove.reduce(async (memo: Promise<void>, key: string) => {
      await memo
      await cache.remove(key)
    }, Promise.resolve())
  }
})
