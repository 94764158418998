export const isBrowser = typeof window !== 'undefined'

export const environment = (): string | undefined => {
  if (window.location) {
    const url = window.location

    if (url.host.includes('localhost') || url.host.includes('local')) {
      return 'dev.'
    } else if (url.host.includes('dev')) {
      return 'dev.'
    } else if (url.host.includes('qa')) {
      return 'qa.'
    } else if (url.host.includes('stage')) {
      return 'stage.'
    } else {
      return ''
    }
  }
}

export const getCookie = (name: string): string | undefined => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

export const formatDate = (date: string): string =>
  new Intl.DateTimeFormat('default', { dateStyle: 'medium' }).format(new Date(date))

export const formatLocalDate = (date: string, trimYear?: boolean): string => {
  if (!date) return ''
  return new Date(new Date(date).toISOString()).toLocaleDateString('en-US', {
    timeZone: 'UTC',
    day: 'numeric',
    month: 'short',
    year: trimYear ? undefined : 'numeric'
  })
}

export const formatPrice = (price: number, currency = 'USD'): string =>
  new Intl.NumberFormat('default', { style: 'currency', currency }).format(price)

export const getDateFromRange = (date: string, range: number = 1): boolean => {
  const today = new Date()
  const newDate = new Date(date)
  return newDate ? (today.getTime() - newDate.getTime()) / (1000 * 60 * 60 * 24) < range : false
}

export const states = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
]
