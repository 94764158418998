import { type AuthState } from './types'

export const initialAuthState: AuthState = {
  isAuthenticated: false
}

export const authReducer = (state: AuthState, action: any): AuthState => {
  switch (action.type) {
    case 'GET_ACCESS_TOKEN_COMPLETE':
      if (state.user === action.user) {
        return state
      }
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined
      }
    case 'ERROR':
      return {
        ...state,
        isAuthenticated: false,
        error: action.error
      }
    default:
      return state
  }
}
