import { type ClientStorage } from './types'

export const clientStorage: ClientStorage = {
  set: (key: string, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  get: (key: string) => {
    if (typeof sessionStorage === 'undefined') return

    const value = sessionStorage.getItem(key)

    if (value == null) return

    return JSON.parse(value)
  },
  remove: (key: string) => {
    sessionStorage.removeItem(key)
  }
}

export const storageManager = (storage: any, clientId: string): any => ({
  create: (state: any) => {
    storage.set(`myaccount.auth.${clientId}`, state)
  },
  get: () => storage.get(`myaccount.auth.${clientId}`),
  remove: () => {
    storage.remove(`myaccount.auth.${clientId}`)
  }
})
