/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { navigate, type WrapRootElementNodeArgs } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { ClientProvider } from './src/apollo/ClientContext'
import { AuthProvider } from './src/auth/AuthContext'
import { type AppState } from './src/auth/types'

const onRedirectCallback = async (appState: AppState): Promise<void> => {
  await navigate(appState?.returnTo ?? '/', { replace: true })
}

export const wrapRootElement = ({ element }: WrapRootElementNodeArgs): JSX.Element => (
  <AuthProvider
    clientId={process.env.GATSBY_CLOUD_LOGIN_CLIENTID!}
    domain={process.env.GATSBY_CLOUD_LOGIN_DOMAIN!}
    redirectUri={process.env.GATSBY_MYACCOUNT_URL!}
    onRedirectCallback={onRedirectCallback}
  >
    <ClientProvider>{element}</ClientProvider>
  </AuthProvider>
)

const HiddenWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const shouldHide = new URLSearchParams(window.location.search).get('kickoffNcmAuthorizeFlow') === 'true';
    setIsHidden(shouldHide);
    console.log('HiddenWrapper: Should hide?', shouldHide);
    
    if (shouldHide) {
      document.body.style.display = 'none';
    } else {
      document.body.style.display = '';
    }

    return () => {
      document.body.style.display = '';
    };
  }, []);

  return <>{children}</>;
};

export function wrapPageElement({ element, props }: any): JSX.Element {
  console.log('wrapPageElement called', { elementType: element.type.name, hasLayout: !!element.type.Layout });
  
  const PageComponent = element.type;
  const Layout = PageComponent.Layout ?? React.Fragment;

  return (
    <HiddenWrapper>
      <Layout {...props}>{element}</Layout>
    </HiddenWrapper>
  );
}