import axios from 'axios'
import { isBrowser, getCookie } from '../util'
import { CACHE_KEY_USER_SUFFIX, cacheInMemory, cacheManager } from './cache'
import { clientStorage, storageManager } from './storage'
import {
  type AppState,
  type AuthClientOptions,
  type PKCERequestOptions,
  type RefreshTokenRequestOptions
} from './types'
import { generateCodeVerifier, generateCodeChallenge, parseQueryResult } from './utils'

const WebAuth = (clientOptions: AuthClientOptions): any => {
  const { clientId, domain, redirectUri } = clientOptions
  const clientSessionStorage = storageManager(clientStorage, clientId)
  const clientCache = cacheManager(cacheInMemory)
  const cloudUrl = `https://${domain}`
  const oauthUrl = `${cloudUrl}/account/oauth`
  const authApi = axios.create({
    baseURL: `${oauthUrl}`,
    withCredentials: true,
    headers: {
      Authorization: `Basic ${
        isBrowser ? window.btoa(`${clientId}:`) : Buffer.from(`${clientId}:`).toString('base64')
      }`
    }
  })

  authApi.interceptors.response.use(
    response => response.data,
    async error => await Promise.reject(error)
  )

  const handleRedirectCallback = async (url: string = window.location.href): Promise<AppState> => {
    console.group('[Redirect Callback]')
    const queryParams = url.split('?').slice(1)

    if (queryParams.length === 0) {
      throw new Error('There are no query params available for parsing.')
    }

    const { code, error, error_description } = parseQueryResult(queryParams.join(''))

    const authSession = clientSessionStorage.get()

    // Session Storage should have a `codeVerifier` to do PKCE for CSRF protection
    if (!authSession?.codeVerifier) {
      throw new Error('Invalid session')
    }

    clientSessionStorage.remove()

    if (error) {
      throw new Error(error_description)
    }

    const codeVerifier = authSession.codeVerifier
    const appState = authSession.appState

    await requestToken({
      grant_type: 'authorization_code',
      scope: 'read',
      code: code as string,
      code_verifier: codeVerifier,
      redirect_uri: redirectUri
    })

    console.groupEnd()

    return {
      appState
    }
  }

  const loginWithRedirect = async (opts: AppState = {}): Promise<any> => {
    const riskifiedId = getCookie('FNL_RISKIFIED_ID') ?? `${generateCodeVerifier()}_${Date.now()}`
    const codeVerifier = generateCodeVerifier()
    const codeChallenge = await generateCodeChallenge(codeVerifier)
    const authorizeUrl = `${oauthUrl}/authorize?${new URLSearchParams({
      client_id: clientId,
      redirect_uri: redirectUri,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      state: 'myaccount',
      scope: 'read',
      response_type: 'code',
      riskified_id: riskifiedId
    }).toString()}`

    clientSessionStorage.create({
      codeVerifier,
      appState: opts
    })

    if (isBrowser) {
      window.location.assign(authorizeUrl)
    }
  }

  const logout = async (opts: AppState = {}): Promise<any> => {
    const url = `${cloudUrl}/account/logout?${new URLSearchParams(opts).toString()}`
    await clientCache.clear()
    cacheInMemory.remove(CACHE_KEY_USER_SUFFIX)
    if (isBrowser) {
      document.cookie.split(';').forEach(c => {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
      })
      window.location.assign(url)
    }
  }

  const getTokenSilently = async (opts: any = {}): Promise<any> => {
    const cache = await clientCache.getUser(clientId)
    const authResult = await requestToken({
      grant_type: 'refresh_token',
      scope: 'read',
      refresh_token: cache?.refreshToken,
      redirect_uri: redirectUri
    })

    return authResult
  }

  const requestToken = async ({
    ...opts
  }: PKCERequestOptions | RefreshTokenRequestOptions): Promise<any> => {
    const params = new URLSearchParams(opts)
    const authResult: any = await authApi.post(`/token?${params}`)
    const userInfo = {
      customerId: authResult.customerId,
      loyaltyId: authResult.loyaltyId
    }

    await saveEntryInCache({
      refreshToken: authResult.refresh_token,
      userInfo
    })

    return { ...authResult, userInfo }
  }

  const saveEntryInCache = async (entry: any): Promise<any> => {
    const { refreshToken, userInfo } = entry

    cacheInMemory.set(CACHE_KEY_USER_SUFFIX, { refreshToken, userInfo })
    await clientCache.setUser(clientId, refreshToken, userInfo)
    await clientCache.set({ clientId, refreshToken })
  }

  const getUserInfoFromCache = async (): Promise<any> => {
    const cache = await clientCache.getUser(clientId)
    const currentCache = cacheInMemory.get(CACHE_KEY_USER_SUFFIX)

    if (cache && cache?.userInfo === currentCache?.userInfo) {
      return currentCache
    }

    cacheInMemory.set(CACHE_KEY_USER_SUFFIX, cache)
    return cache
  }

  const getUser = async (): Promise<any | undefined> => {
    const userCache = await getUserInfoFromCache()
    return userCache?.userInfo
  }

  return {
    handleRedirectCallback,
    loginWithRedirect,
    logout,
    getTokenSilently,
    getUser
  }
}

export default WebAuth
